import React, { Fragment } from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import ItalicHeading from "../components/ui/ItalicHeading"
import FaqRow from "../components/ui/FaqRow"
import { edgeToFaq } from "../lib/utils"
import groupBy from 'lodash/groupBy'
import sortBy from "lodash/sortBy"
import { graphql } from 'gatsby'

const Wrapper = styled.div`
    padding: 60px 0;
`

const Title = styled.h2`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.43px;
    text-transform: uppercase;
    color: #250044;
    text-align: center;
    margin: 60px auto 0;
`

const FaqRowList = styled.div`
    margin: 60px auto 0;
`

const FaqPage = ({ data }) => {

    const faqs = data.faqs.edges.map(edge => edgeToFaq({ edge }))
    const groups = groupBy(faqs, 'category')
    const categories = ['General Questions', 'Our Providers', 'Our Care Managers', 'Medication', 'Account & Payment']

    return <Layout>
        <SEO title="FAQs" />
        <Wrapper>
            <ItalicHeading>FAQ's</ItalicHeading>
            {categories.map(key => {

                return <Fragment key={key}>
                    <Title>{key}</Title>
                    <FaqRowList>{sortBy(groups[key], 'order').map(faq => <FaqRow key={faq.question} question={faq.question} answer={faq.answer} />)}</FaqRowList>
                </Fragment>
            })}
        </Wrapper>
    </Layout>
}

export const query = graphql`
  query FaqPageQuery {
    faqs: allPrismicFaq {
        edges {
            node {
                    data {
                        answer {
                            html
                        }
                        question {
                            text
                        }
                        order
                        category
                    }
                }
            }
        }
    }`

export default FaqPage