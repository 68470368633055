import React, { useState } from 'react'
import styled from 'styled-components'
import Container from './Container'
import faqCloseImage from '../../images/faq-close.svg'
import faqOpenImage from '../../images/faq-open.svg'

const Wrapper = styled.div`
    text-align: left;
    position: relative;
    background-color: ${({ open }) => open ? '#D4DBF5' : '#fff'};
    border-bottom: 1px solid #E1E5F5;
    transition: background-color .4s;
`
const Header = styled.div`
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`
const Text = styled.div`
    padding: 0 0 30px;
    font-size: 16px;
    line-height: 30px;
    color: #250044;

    b,strong {
        font-weight: 600;
    }
    
    p {
        margin: 20px 0 0;
    }

    ul {
        list-style: disc;
        padding: 10px 0 0 20px;
    }
`
const Title = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.43px;
    color: #250044;
    flex: 1;
`
const Toggle = styled.div` 
    background: #F0F1F4;
    width: 40px;
    height: 40px;
    right: 5px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #AABAF9;
    font-size: 24px;
`

export default function FaqRow({ question, answer }) {

    const [open, setOpen] = useState(false)

    function onToggle() {

        setOpen(open => !open)
    }

    return <Wrapper open={open}>
        <Container>
            <Header onClick={onToggle}>
                <Title>{question}</Title>
                <Toggle>
                    {open && <img src={faqCloseImage} alt="close icon" />}
                    {!open && <img src={faqOpenImage} alt="open icon" />}
                </Toggle>
            </Header>
            {open &&
                <Text dangerouslySetInnerHTML={{ __html: answer }} />
            }
        </Container>
    </Wrapper>
}